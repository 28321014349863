<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>资源国际化</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-flex-box">
         <!-- 左表格 -->
         <el-col :span="6" class="left-table-bg">
            <el-row class="left-table">
               <el-table
                     ref="sideTable"
                     v-loading="sideLoad"
                     :data="sideData"
                     border fit
                     highlight-current-row
                     @current-change="handleCurrentChange"
                     row-key="id"
                     style="width: 100%;"
                     max-height="780"
                     :stripe="true"
                     size="medium">
                  <el-table-column label="语言名称">
                     <template slot-scope="scope">
                        <p>{{scope.row.langChina + `（${scope.row.langName}）`}}</p>
                     </template>
                  </el-table-column>
                  <el-table-column label="操作">
                     <template slot-scope="scope">
                        <el-button @click="handleSideEdit(scope.row)" type="text" icon="el-icon-edit" >
                           <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                        </el-button>
                        <el-button @click="handleSideDel(scope.row.id)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                           <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
            </el-row>
         </el-col>
         <!-- 右表格 -->
         <el-col :span="18" class="right-table-bg">
            <el-row class="right-table">
               <!-- 查询框 -->
               <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label>资源实体：</label>
                        <el-select  class="width-220"  v-model="resourceEntity" @focus="getSubDict" >
                           <el-option
                                 v-for="item in resources"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                           </el-option>
                        </el-select>
                     </el-row>
                     <el-row class="search-item">
                        <label>资源名称：</label>
                        <el-input  class="width-220"  ref="inputName" v-model="resourceChinaName" placeholder="资源名称" clearable></el-input>
                     </el-row>
                     <el-button  type="primary" icon="el-icon-search" @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                     <el-button  type="primary" icon="el-icon-refresh-right"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
                  </el-row>
               </el-row>
               <!-- 表格 -->
               <el-row class="table-box">
                  <el-table
                        ref="mainTable"
                        v-loading="tableLoad"
                        :data="tableData"
                        border fit
                        style="width: 100%"
                        :stripe="true">
                     <el-table-column prop="number" label="序号" width="60">
                        <template slot-scope="scope">
                           {{scope.$index + 1}}
                        </template>
                     </el-table-column>
                     <el-table-column prop="resourceEntity" label="资源实体"></el-table-column>
                     <el-table-column prop="resourceChinaName" label="资源名称"></el-table-column>
                     <el-table-column prop="code" label="语言编码"></el-table-column>
                     <el-table-column prop="name" label="译文" min-width="160">
                        <template slot-scope="scope">
                           <p v-show="!scope.row.isEdit" @click="scope.row.isEdit = true" v-text="scope.row.name || '—'"></p>
                           <input v-show="scope.row.isEdit" @blur="scope.row.isEdit = false" class="input-name" type="text" v-model="scope.row.name">
                        </template>
                     </el-table-column>
                     <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
                     <el-table-column :label="$t('msg.operating')" fixed="right" min-width="150">
                        <template slot-scope="scope">
                           <el-button type="text" @click="handleSave(scope.row)">
                              <el-link type="primary" v-text="'保存'">保存</el-link>
                           </el-button>
                           <el-button type="text" style="color: #F56C6C;"  @click="handleClear(scope.row.id)">
                              <el-link type="danger" v-text="'清空'">清空</el-link>
                           </el-button>
                        </template>
                     </el-table-column>
                  </el-table>
               </el-row>
            </el-row>
         </el-col>
         <!-- 新增/编辑弹窗 -->
         <el-dialog :title="`${action === 'add' ? '添加' : '编辑'}国际化语言`" :before-close="handleClose" :visible.sync="isShow" width="550px">
            <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
               <el-form-item label="语言中文名称：" prop="langChina">
                  <el-input  class="width-200" v-model="ruleForm.langChina" placeholder="" clearable></el-input>
               </el-form-item>
               <el-form-item label="语言名称：" prop="langName">
                  <el-input  class="width-200" v-model="ruleForm.langName" placeholder="" clearable></el-input>
               </el-form-item>
               <el-form-item label="语言编码：" prop="langCode">
                  <el-input  class="width-200" v-model="ruleForm.langCode" placeholder="" clearable></el-input>
               </el-form-item>
               <el-form-item label="备注信息：">
                  <el-input class="width-200 type-textarea" type="textarea" v-model="ruleForm.remark" placeholder=""></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                 <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
                 <el-button  type="primary" @click="handleSideSave()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { system } from '@/api/interface/system'
   export default {
      data(){
         return{
            resources: [],              // 资源实体列表
            sideData: [],               // 国际语言列表
            tableData: [],              // 资源译文列表
            sideLoad: true,             // 侧边栏加载动画
            tableLoad: true,            // 表格加载动画
            boxIcon: true,              // 侧边栏折叠状态
            action: 'add',              // 当前操作类型
            isShow: false,              // 是否显示添加/编辑弹窗
            id: '',                     // 编辑id
            ruleForm: {
               langChina: '',           // 语言中文名称
               langName: '',            // 语言名称
               langCode: '',            // 语言编码
               remark: '',                 // 备注
            },
            rules: {
               langChina: [
                  { required: true, message: '请输入语言中文名称', trigger: 'blur' }
               ],
               langName: [
                  { required: true, message: '请输入语言名称', trigger: 'blur' }
               ],
               langCode: [
                  { required: true, message: '请输入语言编码', trigger: 'blur' }
               ]
            },
            /* 查询过滤字段 */
            resourceEntity: '系统设置',  // 资源实体
            resourceChinaName: '',      // 资源名称
            lang: '',                   // 当前语言编码
            /* 弹窗提示文本 */
            add_success: '添加成功！',
            update_success: '修改成功！',
            del_success: '删除成功！',
            confirm_remove: '确定移除？',
            modify_before: '请修改后再保存！',
            name_no_null: '译文不能为空！',
            prompt: '提示！',
            confirm: '确定',
            cancel: '取消'
         }
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getLanguages()
      },
      methods: {
         // 获取国际语言列表
         getLanguages(){
            const url = system.getLanguages
            this.$axios.post(url, {}).then(res => {
               if (res.success){
                  this.sideLoad = false
                  this.sideData = res.records
                  if (this.sideData.length === 0) return
                  this.$refs.sideTable.setCurrentRow(this.sideData[0])
                  this.lang = this.sideData[0].code
                  this.getResTranslations()
               }
            })
         },
         // 添加国际语言
         handleSideAdd(){
            this.isShow = true
            this.action = 'add'
         },
         // 编辑国际语言
         handleSideEdit(row){
            for (let k in row) k in this.ruleForm && (this.ruleForm[k] = row[k])
            this.id = row.id
            this.isShow = true
            this.action = 'edit'
         },
         // 重置表单
         resetForm(){
            for (let k in this.ruleForm) this.ruleForm[k] = ''
         },
         // 保存操作
         handleSideSave(){
            this.$refs.ruleForm.validate(valid => {
               if (!valid) return
               let url = system.addLanguage
               let param = { ...this.ruleForm }
               if (this.action === 'edit'){
                  url = system.editLanguage
                  param.id = this.id
               }
               this.$axios.post(url, param).then(res => {
                  if (res.success){
                     this.$message({
                        showClose: true,
                        message: this.action === 'add' ? '添加成功' : '编辑成功',
                        type: 'success'
                     })
                     this.getLanguages()
                     this.handleClose()
                  }
               })
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.resetForm()
         },
         // 删除语言
         handleSideDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let ids = []; ids.unshift(id)
               const param = { ids: ids.toString() }
               const url = system.delLanguage
               this.$axios.post(url, param).then((res) => {
                  if (res.success){
                     this.$message({ showClose: true, message: this.del_success, type: 'success'})
                     this.getLanguages()
                  }
               })
            })
         },
         // 获取资源译文列表
         getResTranslations(){
            let resourceEntity
            this.resourceEntity === '系统设置' ? resourceEntity = 'SYS_MENU' : resourceEntity = this.resourceEntity
            const url = system.getLangResList
            const param = { resourceEntity, lang: this.lang, resourceChinaName: this.resourceChinaName }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.tableLoad = false
                  let tableData = res.records
                  tableData.forEach(item => {
                     item.isEdit = false
                     item.resourceEntity = resourceEntity
                  })
                  this.tableData = tableData
               }
            })
         },
         // 获取资源实体列表
         getSubDict(){
            if (this.resources.length !== 0) return
            const url = system.subDict + '/resource-entity'
            this.$axios.get(url, {}).then(res => {
               if (res.success){
                  let resources = res.records
                  if (resources.length === 0) return resources[0] = 0
                  resources = resources.map(item => ({value: item.dictKey, label: item.dictVal}))
                  this.resources = resources
               }
            })
         },
         // 搜索资源译文列表
         handleQuery(bool){
            if (bool) return this.getResTranslations()
            this.resourceEntity = '系统设置'
            this.resourceChinaName = ''
            this.getResTranslations()
         },
         // 侧边栏当前选中
         handleCurrentChange(row){
            this.lang = row.code
            this.getResTranslations()
         },
         // 保存资源译文
         handleSave(row){
            if (!row.name) {
               this.$message({
                  showClose: true,
                  message: this.name_no_null,
                  type: 'warning'
               })
               return
            }
            const url = system.saveLangResource
            const param = {
               id: row.id,
               name: row.name,
               lang: row.code,
               resourceId: row.resourceId,
               resourceEntity: this.resourceEntity === '系统设置' ? 'SYS_MENU' : this.resourceEntity
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.update_success,
                     type: 'success'
                  })
                  this.getResTranslations()
               }
            })
         },
         // 清楚资源译文
         handleClear(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               let ids = []; ids.unshift(id)
               const param = { ids: ids.toString() }
               const url = system.clearLangRes
               this.$axios.post(url,param).then((res) => {
                  if (res.success){
                     this.$message({
                        showClose: true,
                        message: this.del_success,
                        type: 'success'
                     })
                     this.getResTranslations()
                  }
               }).catch((error) => {
                  console.log(error)
               })
            })
         }
      },
      filters: {
      }
   }
</script>

<style scoped lang="scss">
   .top-title{
      line-height: 40px;
      font-size: 12px;
      background: #f2f2f2;
      color: #666666;
      padding: 0 10px;
      font-weight: bold;
      border: solid 1px #EBEEF5;
   }
   .top-title i{
      font-weight: bold;
      font-size: 16px;
      float: right;
      line-height: 40px;
   }
   .input-name{
      width: 100%;
   }
</style>
